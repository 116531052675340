import * as React from 'react';
import Centergrid from './components/Centergrid';
import data from './Answers.json';





function App() {

  const [answers] = React.useState(data);

  
  return (
    <React.Fragment>
      <div className='sm:hidden'>
          <Centergrid data={answers}/>
      </div>
      <div className=' hidden sm:block place-items-center text-center font-light text-pretty align-middle absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"'>
          Please turn your phonski into a portrait mode. 
      </div>
    </React.Fragment>
  );
}

export default App;
