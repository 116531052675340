
import * as React from 'react';
import {useState} from 'react';
import JGames from '../images/JGames.gif'
import {FaRegQuestionCircle} from 'react-icons/fa'
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";


export default function Centergrid({...props}) {

  const [currentPuzzle, setCurrentPuzzle] = React.useState(0)
  const [dataBoxes, setDataBoxes] = useState(props.data[currentPuzzle].boxes.sort((a, b) => 0.5 - Math.random()))
  const [dataAnswers, setDataAnswers] = useState(props.data[currentPuzzle].answers)
  const [nrOfSelectedBoxes, setNrOfSelectedBoxes] = useState(0)
  const [mistakes, setMistakes] = useState(4)
  const [hidden, setHidden] = React.useState(false)
  const [showMenu, setShowMenu] = React.useState(false)
  const [dates] = React.useState(props.data.map((item) => item.datePlayed))
  

  const dateIsSelected = (e) => {
    dates.map((item, index) => {
      if (item === e.target.innerText) {
        deselectAllBoxes()
        setCurrentPuzzle(index)
        setDataBoxes(props.data[index].boxes.sort((a, b) => 0.5 - Math.random()))
        setDataAnswers(props.data[index].answers)
        setMistakes(4)
        setHidden(true)
        setShowMenu(false)
      }
      return null; // Add a return statement here
    })
    showHideMenu()
  }

  const showHideMenu = () => {
    setShowMenu(!showMenu)
  }

  const showInfo = () => {
    setHidden(!hidden)
  }

  const solveAnswer = (id) => {
    let tempBoxes = [...dataBoxes]
    let tempAnswers = [...dataAnswers]

    // eslint-disable-next-line array-callback-return
    dataBoxes.map((item, index) => {
      if (item.answerid === id) {
        item.solved = true
        tempBoxes[index] = item
      }
    })
    setDataBoxes(tempBoxes)
    // eslint-disable-next-line array-callback-return
    dataAnswers.map((item, index) => {
      if (item.id === id) {
        item.solved = true
        tempAnswers[index] = item
      }
    })
    setDataAnswers(tempAnswers)

  }


  // eslint-disable-next-line array-callback-return
  const answerSubmitted = () => {
    let modData = []
    // eslint-disable-next-line array-callback-return
    dataBoxes.filter((item) => {
      if(item.selected){
        modData.push(item.answerid)
      }
    })

    console.log(modData.length)

    if (modData[0] === 0 && modData[1] === 0 && modData[2] === 0 && modData[3] === 0) {
      solveAnswer(0)
      deselectAllBoxes()
    } 
    else if (modData[0] === 1 && modData[1] === 1 && modData[2] === 1 && modData[3] === 1)
    {
      solveAnswer(1)
      deselectAllBoxes()
    }
    else if (modData[0] === 2 && modData[1] === 2 && modData[2] === 2 && modData[3] === 2)
    {
      solveAnswer(2)
      deselectAllBoxes()
    }else if (modData[0] === 3 && modData[1] === 3 && modData[2] === 3 && modData[3] === 3)
    {
      solveAnswer(3)
      deselectAllBoxes()
    }
    else 
    {
      setMistakes(mistakes - 1)
      if(modData.filter((item) => item === 0).length === 3 || modData.filter((item) => item === 1).length === 3 || modData.filter((item) => item === 2).length === 3 || modData.filter((item) => item === 3).length === 3){
      alert('One off...')} else if (mistakes === 1) {
        alert('Seriously? You are out of tries!')
        solveAnswer(0)
        solveAnswer(1)
        solveAnswer(2)
        solveAnswer(3)
      } else {
        alert('Nope!')
      }
    }

    //setNrOfSelectedBoxes(0)
  }

  const shuffleArray = () => {
    let modData = [...dataBoxes]
    modData.sort(() => Math.random() - 0.5)
    setDataBoxes(modData)
    
  }

  const deselectAllBoxes = () => {
    let modData = [...dataBoxes]
    // eslint-disable-next-line array-callback-return
    dataBoxes.map((item, index) => {
      item.selected = false
      modData[index] = item
    })
    setDataBoxes(modData)
    setNrOfSelectedBoxes(0)
  }
  const selectBox = (e) => { 

    let modData = [...dataBoxes]
    // eslint-disable-next-line array-callback-return
    dataBoxes.map((item, index) => {
      

      if (item.selected && item.text === e.target.innerText) {
        item.selected = false
        setNrOfSelectedBoxes(nrOfSelectedBoxes - 1)    
        modData[index] = item
      } else if (!item.selected && item.text === e.target.innerText && nrOfSelectedBoxes < 4) {
        item.selected = true
        setNrOfSelectedBoxes(nrOfSelectedBoxes + 1)
        modData[index] = item
      }
    })
    setDataBoxes(modData)
    
  }





  
  return (
    <React.Fragment>


<div className='flex items-center justify-center border-b '>
        
        <div className='justify-start pl-2' >
          <FiMenu className='h-6 w-6 ' onClick={showHideMenu}/>
        </div>
        <div className='justify-center pl-20'>
          <img className='w-3/5 p-3' src={JGames} alt="Jenn Games" /> 
        </div>
      </div>
      <div className='flex justify-end border-b w-screen p-3'>
        <FaRegQuestionCircle className='h-6 w-6' onClick={showInfo}/>
      </div>


      <div className={showMenu ? "fixed left-2 top-2 w-1/3 h-fit border-spacing-1 shadow-xl border-2 px-4 pb-4 bg-white rounded-xl " : "hidden" }>
        {
          dates.map((item, index) => {
            return (  
                <div key= {index} className='text-center text-black font-bold pt-4' onClick={dateIsSelected} >
                  {item}
                </div>
            )
        }
        )
        }
      </div>


       <div className={hidden ? "hidden" : "block bg-white align-middle justify-center border-2 rounded-xl w-screen h-screen "}>
      <div className='flex justify-end p-3'>
        <IoClose className='h-6 w-6' onClick={showInfo}/>
      </div>
        <div className='text-left font-light px-4 py-3' >
          <p className='font-extrabold font-mono text-2xl pb-4'>How to play Conniptions</p>
          <p className='font-bold font-sans text-normal  pb-4'>Find groups of four items that share something in common and very specific to Jennifer. </p>
          <ul>
            <li>
              Select four items and tap 'Submit' to check if your guess is correct.
            </li>
            <li>
              Find the groups without making 4 mistakes!
            </li>
          </ul>
          <br/>
          <p className='font-bold font-sans text-normal  pb-4'>Category Examples </p>
          <ul>
            <li>
              EXPANDABLES: Burkie, Eren, Rees, Lola.
            </li>
            <li>
            FIRE ___: Alarm, Cracker, Truck, My Ass.
            </li>
          </ul>
          <br/>
          <p>Categories always will be more specific than "4-LETTER OBSENITIES", "BELGIAN BARS" or "GPS COORDINATES". </p>
          <br/>

          <p>Each puzzle has exactly one solution. Watch out for words that seem to belong to multiple categories. Legit. Watch out!</p>
          <br/>
          <p>And finally, it took a week to put this puppy together and life is too short for debugging and testing. So if something breaks - it's your fault.</p>
          <p className =' text-3xl text-center'> 🙈 </p>
        </div>
        </div>


      {hidden && <section>
        <div  className=' text-center font-light py-2'>
          Create four groups of four! 
        </div>

      <div className= 'grid grid-cols-1 gap-2 px-3'>
        {dataAnswers.map((item, index) => {
          return (
            item.solved && 
            <div key= {index} className={'px-10 rounded-lg'} style={{ backgroundColor: `${item.color}` }} > 
              <div className='text-center font-bold pt-4 '>
                {item.name}
              </div>
              <div className= 'text-center font-light underline pb-6'>                
                  {item.id === 0 && dataBoxes.map((boxy, index)=>{ return ( boxy.answerid === 0 && boxy.text + ", " )} )}
                  {item.id === 1 && dataBoxes.map((boxy, index)=>{ return ( boxy.answerid === 1 && boxy.text + ", " )} )}
                  {item.id === 2 && dataBoxes.map((boxy, index)=>{ return ( boxy.answerid === 2 && boxy.text + ", " )} )}
                  {item.id === 3 && dataBoxes.map((boxy, index)=>{ return ( boxy.answerid === 3 && boxy.text + ", " )} )}
                

              </div>
            </div>
          )
        })}
      </div>

    <div className='min-h-full flex items-top'>
      <div className='flex-1 max-w-4x1 mx-auto '>
        <ul className='grid grid-cols-4 gap-2 p-2'>

          { 
            dataBoxes.map((item, index) => {
              return (
                !item.solved &&
                <li key={index} onClick={selectBox} className= "bg-gray-100 shadow-md h-full rounded-lg " style={{ backgroundColor: item.selected ? "#4b5563" : "#f3f4f6" }}>
                  <div className='w-24 h-24 flex justify-center items-center font-semibold text-center ' style={{ color: item.selected ? "#fff" : "#000" }}>
                    {item.text}
                  </div>
                </li>
              )
            })
          }
          
        </ul>
      </div>
    </div>
      <div className=' text-center font-light py-6'>
        Mistakes remaining: {mistakes === 1 && "🖤"} {mistakes === 2 && "🖤 🖤"} {mistakes === 3 && "🖤 🖤 🖤"} {mistakes === 4 && "🖤 🖤 🖤 🖤"} 
      </div>
      <div className='w-screen grid grid-cols-3 text-center font-light gap-2 px-2'>
 
      <button className="bg-transparent hover:bg-gray-200 text-black font-semibold py-4 border border-black  rounded-full px-3"onClick={shuffleArray} >
          Shuffle
      </button>
      <button className="bg-transparent hover:bg-gray-200 text-black font-semibold py-2 px-3 border border-black  rounded-full " onClick={deselectAllBoxes}>
      Deselect All
      </button>
      <button disabled={nrOfSelectedBoxes < 4} className="font-semibold py-4 border border-black rounded-full px-3" style={{color: nrOfSelectedBoxes === 4 ? "#fff" : "#aaa", backgroundColor: nrOfSelectedBoxes === 4 ? "#000" : "#fff"}} onClick={answerSubmitted}>
          Submit
      </button>
  
      
      </div>    
</section>}
    </React.Fragment>

  );
}
